var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "admin-edit-user" },
        [
          _c(
            "b-tabs",
            { attrs: { lazy: "" } },
            [
              _c("b-tab", { attrs: { title: "General" } }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col s4",
                      class: { "form-error": _vm.$v.userInternal.name.$error }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "device-label",
                          attrs: { for: "user_name" }
                        },
                        [_vm._v("User Name")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.userInternal.name.$model,
                            expression: "$v.userInternal.name.$model"
                          }
                        ],
                        staticClass: "text-field w-input",
                        attrs: {
                          type: "text",
                          maxlength: "256",
                          placeholder: "Full Name",
                          id: "user_name"
                        },
                        domProps: { value: _vm.$v.userInternal.name.$model },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.$v.userInternal.name,
                              "$model",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      !_vm.$v.userInternal.name.required
                        ? _c("div", { staticClass: "input-error" }, [
                            _vm._v("Field is Required")
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-4",
                      class: { "form-error": _vm.$v.userInternal.email.$error }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "device-label",
                          attrs: { for: "email_address" }
                        },
                        [_vm._v("Email")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.userInternal.email.$model,
                            expression: "$v.userInternal.email.$model"
                          }
                        ],
                        staticClass: "text-field w-input",
                        attrs: {
                          type: "text",
                          maxlength: "256",
                          placeholder: "Email Address",
                          id: "email_address"
                        },
                        domProps: { value: _vm.$v.userInternal.email.$model },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.$v.userInternal.email,
                              "$model",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      !_vm.$v.userInternal.email.required
                        ? _c("div", { staticClass: "input-error" }, [
                            _vm._v("Field is Required")
                          ])
                        : _vm._e(),
                      !_vm.$v.userInternal.email.email
                        ? _c("div", { staticClass: "input-error" }, [
                            _vm._v("Must contain a valid email address")
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-4",
                      class: { "form-error": _vm.$v.userInternal.mobile.$error }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "device-label",
                          attrs: { for: "Registration" }
                        },
                        [_vm._v("Mobile Number")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.userInternal.mobile.$model,
                            expression: "$v.userInternal.mobile.$model"
                          }
                        ],
                        staticClass: "text-field w-input",
                        attrs: {
                          type: "text",
                          maxlength: "256",
                          name: "Registration",
                          "data-name": "Registration",
                          placeholder: "000 000",
                          id: "Registration"
                        },
                        domProps: { value: _vm.$v.userInternal.mobile.$model },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.$v.userInternal.mobile,
                              "$model",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      !_vm.$v.userInternal.mobile.required
                        ? _c("div", { staticClass: "input-error" }, [
                            _vm._v("Field is Required")
                          ])
                        : _vm._e(),
                      !_vm.$v.userInternal.mobile.minLength ||
                      !_vm.$v.userInternal.mobile.maxLength
                        ? _c("div", { staticClass: "input-error" }, [
                            _vm._v("Must be 10 digits")
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c("div", { staticClass: "spacing" })
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col s12" }, [
                    _vm._v(
                      " LastLogin: " + _vm._s(_vm.userInternal.last_login) + " "
                    )
                  ])
                ])
              ]),
              !_vm.isNewUser
                ? _c(
                    "b-tab",
                    { attrs: { title: "Devices" } },
                    [
                      _c("div", { staticClass: "toolbar" }, [
                        _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "icon-button",
                          class: _vm.$config.icons.general.assign,
                          attrs: { title: "Assign Device" },
                          on: { click: _vm.clickAddDevice }
                        }),
                        _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "icon-button-danger",
                          class: _vm.$config.icons.general.unassign,
                          attrs: { title: "Unassign Selected Devices" },
                          on: { click: _vm.clickUnassignSelected }
                        })
                      ]),
                      _c("admin-device-list", {
                        attrs: {
                          "hide-filter": "",
                          devices: _vm.userInternal.devices,
                          "show-fields": _vm.deviceFields
                        },
                        on: {
                          change: _vm.changeSelection,
                          save: _vm.getUserData
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-tab",
                { attrs: { title: "Subscriptions" } },
                [
                  _c("div", [
                    _vm._v(
                      "NOTE: It's usually best to change a user's Subscription in the Stripe Dashboard!"
                    )
                  ]),
                  _c("div", { staticClass: "col s4" }, [
                    _c(
                      "label",
                      {
                        staticClass: "device-label",
                        attrs: { for: "stripe_id" }
                      },
                      [_vm._v("Stripe ID")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.userInternal.stripe_id,
                          expression: "userInternal.stripe_id"
                        }
                      ],
                      staticClass: "text-field w-input",
                      attrs: {
                        type: "text",
                        maxlength: "256",
                        placeholder: "Stripe ID",
                        id: "stripe_id",
                        disabled: ""
                      },
                      domProps: { value: _vm.userInternal.stripe_id },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.userInternal,
                            "stripe_id",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _c("admin-subscription-list", {
                    attrs: { subscriptions: _vm.userInternal.subscriptions },
                    on: { change: _vm.getUserData }
                  })
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Roles" } },
                [
                  _c("div", { staticClass: "toolbar" }, [
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "icon-button",
                      class: _vm.$config.icons.general.refresh,
                      attrs: { title: "Reset Roles" },
                      on: {
                        click: function($event) {
                          return _vm.resetRoles()
                        }
                      }
                    })
                  ]),
                  _c("admin-roles-list", {
                    ref: "userRoles",
                    attrs: {
                      roles: _vm.userInternal.admin_roles,
                      "show-fields": _vm.roleFields
                    }
                  })
                ],
                1
              ),
              !_vm.isNewUser
                ? _c(
                    "b-tab",
                    { attrs: { title: "Shares" } },
                    [
                      _c("p", [
                        _vm._v(
                          "NOTE: This screen shows the Shares the user OWNS (i.e. shares they have granted to other users). Changes made here will be performed on behalf of the user, appearing to others as though the user performed them. "
                        )
                      ]),
                      _c("share-device", {
                        attrs: {
                          user_email: _vm.userInternal.email,
                          device_list: _vm.userInternal.devices
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isNewUser
                ? _c(
                    "b-tab",
                    { attrs: { title: "Notifications" } },
                    [
                      _c(
                        "div",
                        { staticClass: "toolbar" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "button",
                              on: {
                                click: function($event) {
                                  return _vm.$bvModal.show(
                                    "modal-user-notifications"
                                  )
                                }
                              }
                            },
                            [_vm._v("Notifications")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "button",
                              on: {
                                click: function($event) {
                                  return _vm.clickClearNotifications()
                                }
                              }
                            },
                            [_vm._v("Clear All")]
                          )
                        ],
                        1
                      ),
                      _c("EditUserNotifications", {
                        attrs: { user: _vm.userInternal }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isNewUser
                ? _c("b-tab", { attrs: { title: "Entitlements" } }, [
                    _c("div", { staticClass: "toolbar" }, [
                      _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "icon-button",
                        class: _vm.$config.icons.general.refresh,
                        attrs: { title: "Refresh List" },
                        on: {
                          click: function($event) {
                            return _vm.getEntitlements(true)
                          }
                        }
                      }),
                      _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "icon-button",
                        class: _vm.$config.icons.general.add,
                        attrs: { title: "Create Entitlement" },
                        on: {
                          click: function($event) {
                            return _vm.$bvModal.show("modal-create-entitlement")
                          }
                        }
                      })
                    ]),
                    _c(
                      "div",
                      { staticClass: "notification-container" },
                      [
                        _c("b-table", {
                          staticClass: "bv-table",
                          attrs: {
                            striped: "",
                            hover: "",
                            items: _vm.entitlements,
                            fields: _vm.entitlementFields,
                            "select-mode": "single",
                            "selected-variant": "success",
                            "th-class": "bv-table-header",
                            "current-page": _vm.currentPage,
                            "per-page": _vm.perPage
                          },
                          on: { "row-clicked": _vm.editEntitlement },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "cell(order_id)",
                                fn: function(row) {
                                  return [
                                    row.item.order_id
                                      ? _c("div", [
                                          _vm._v(
                                            " Order #" +
                                              _vm._s(row.item.order_id) +
                                              " "
                                          ),
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true }
                                              }
                                            ],
                                            staticClass:
                                              "fa fa-external-link row-icon-button",
                                            attrs: { title: "Go to Order" },
                                            on: {
                                              click: function($event) {
                                                return _vm.clickEntitlementOrder(
                                                  row.item
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      : _vm._e(),
                                    !row.item.order_id
                                      ? _c("div", [_vm._v(" Manual ")])
                                      : _vm._e()
                                  ]
                                }
                              },
                              {
                                key: "cell(status)",
                                fn: function(row) {
                                  return [
                                    _c("b-select", {
                                      attrs: {
                                        options: _vm.entitlementStatusOptions
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.changeEntitlementStatus(
                                            $event,
                                            row.item
                                          )
                                        }
                                      },
                                      model: {
                                        value: row.item.status,
                                        callback: function($$v) {
                                          _vm.$set(row.item, "status", $$v)
                                        },
                                        expression: "row.item.status"
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "cell(coupon_code)",
                                fn: function(row) {
                                  return [
                                    _c("b-select", {
                                      attrs: { options: _vm.couponOptions },
                                      on: {
                                        change: function($event) {
                                          return _vm.changeEntitlementCoupon(
                                            $event,
                                            row.item
                                          )
                                        }
                                      },
                                      model: {
                                        value: row.item.coupon_code,
                                        callback: function($$v) {
                                          _vm.$set(row.item, "coupon_code", $$v)
                                        },
                                        expression: "row.item.coupon_code"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            762305082
                          )
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              !_vm.isNewUser
                ? _c(
                    "b-tab",
                    { attrs: { title: "Auditlog" } },
                    [
                      _c("h2", [_vm._v("Audit Log")]),
                      _c("audit-log", {
                        attrs: {
                          related_type: "user",
                          related_id: _vm.user.user_id
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer mt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button btn-warning",
                  on: { click: _vm.getLoginToken }
                },
                [_vm._v("Login As")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button btn-warning",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.show("modal-user-set-password")
                    }
                  }
                },
                [_vm._v("Change Password")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.clickClose()
                    }
                  }
                },
                [_vm._v("Close")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.saveChanges()
                    }
                  }
                },
                [_vm._v("Save Changes")]
              )
            ],
            1
          ),
          _c(
            "b-modal",
            {
              staticClass: "modal-content",
              attrs: {
                id: "modal-assign-device",
                centered: "",
                size: "xl",
                "hide-footer": "",
                title: "Assign Device to User"
              }
            },
            [
              _c("admin-assign-devices", {
                attrs: { user: _vm.userInternal, modal: "modal-assign-device" },
                on: { save: _vm.onAssignDevice }
              })
            ],
            1
          ),
          _c(
            "b-modal",
            {
              staticClass: "modal-content",
              attrs: {
                id: "modal-create-entitlement",
                centered: "",
                size: "m",
                "hide-footer": "",
                title: "Create Entitlement"
              }
            },
            [
              _c("b-select", {
                attrs: { options: _vm.couponOptions },
                model: {
                  value: _vm.newCouponCode,
                  callback: function($$v) {
                    _vm.newCouponCode = $$v
                  },
                  expression: "newCouponCode"
                }
              }),
              _c(
                "div",
                { staticClass: "footer mt-2" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button",
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.hide("modal-create-entitlement")
                        }
                      }
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "button",
                      on: {
                        click: function($event) {
                          return _vm.createEntitlement()
                        }
                      }
                    },
                    [_vm._v("Create Entitlement")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-modal",
            {
              staticClass: "modal-content",
              attrs: {
                id: "modal-user-notifications",
                centered: "",
                size: "xl",
                "hide-footer": "",
                title: _vm.userInternal.name + "'s Notifications"
              }
            },
            [
              _c("NotificationList", {
                attrs: { "user-email": _vm.userInternal.email }
              })
            ],
            1
          ),
          _c(
            "b-modal",
            {
              staticClass: "modal-content",
              attrs: {
                id: "modal-user-set-password",
                centered: "",
                size: "xl",
                "hide-footer": "",
                title: "Set " + _vm.userInternal.name + "'s Password"
              }
            },
            [
              _c(
                "form",
                [
                  _c(
                    "label",
                    {
                      staticClass: "device-label",
                      attrs: { for: "new-password" }
                    },
                    [_vm._v("New Password")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newPassword,
                        expression: "newPassword"
                      }
                    ],
                    staticClass: "text-field w-input",
                    attrs: {
                      type: "password",
                      maxlength: "256",
                      name: "new-password",
                      "data-name": "new-password",
                      placeholder: "New Password",
                      id: "new-password",
                      required: ""
                    },
                    domProps: { value: _vm.newPassword },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.newPassword = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "device-label",
                      attrs: { for: "confirm-password" }
                    },
                    [_vm._v("New Password")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.confirmNewPassword,
                        expression: "confirmNewPassword"
                      }
                    ],
                    staticClass: "text-field w-input",
                    attrs: {
                      type: "password",
                      maxlength: "256",
                      name: "confirm-password",
                      "data-name": "new-password",
                      placeholder: "Confirm Password",
                      id: "confirm-password",
                      required: ""
                    },
                    domProps: { value: _vm.confirmNewPassword },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.confirmNewPassword = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "b-button",
                    {
                      staticClass: "button",
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.hide("modal-user-set-password")
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "button",
                      on: { click: _vm.changeUserPassword }
                    },
                    [_vm._v("Change Password")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-modal",
            {
              staticClass: "modal-content",
              attrs: {
                id: "modal-user-login-token",
                centered: "",
                size: "xl",
                "ok-only": "",
                title: "Login as " + _vm.userInternal.name + "'"
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-8" }, [
                  _c("label", { attrs: { for: "name" } }, [
                    _vm._v("Login Token")
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.loginTokenURL,
                        expression: "loginTokenURL"
                      }
                    ],
                    staticClass: "w-100",
                    attrs: { id: "token" },
                    domProps: { value: _vm.loginTokenURL },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.loginTokenURL = $event.target.value
                      }
                    }
                  }),
                  _c("button", { on: { click: _vm.tokenToClipboard } }, [
                    _vm._v("Copy to Clipboard")
                  ])
                ]),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "hint-box" }, [
                    _c("h4", [_vm._v("Admin Login as User")]),
                    _vm._v(
                      " Copy the below Login URL (with token) and paste it into a new INCOGNITO browser window. This will not interfere with your current protekt login. This should not affect the user's ability to log in or invalidate their existing login token. "
                    )
                  ])
                ])
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }