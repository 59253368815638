<template>
  <div class="admin-edit-user" v-if="!loading">
    <b-tabs lazy>
      <b-tab title="General">
        <div class="row">
          <div class=" col s4" :class="{ 'form-error': $v.userInternal.name.$error }">
            <label for="user_name" class="device-label">User Name</label>
            <input type="text" v-model="$v.userInternal.name.$model" class="text-field w-input"
                   maxlength="256" placeholder="Full Name" id="user_name">
            <div class="input-error" v-if="!$v.userInternal.name.required">Field is Required</div>
          </div>
          <div class="col-4" :class="{ 'form-error': $v.userInternal.email.$error }">
            <label for="email_address" class="device-label">Email</label>
            <input type="text" v-model="$v.userInternal.email.$model" class="text-field w-input"
                   maxlength="256" placeholder="Email Address" id="email_address">
            <div class="input-error" v-if="!$v.userInternal.email.required">Field is Required</div>
            <div class="input-error" v-if="!$v.userInternal.email.email">Must contain a valid email address</div>
          </div>
          <div class="col-4" :class="{ 'form-error': $v.userInternal.mobile.$error }">
            <label for="Registration" class="device-label">Mobile Number</label>
            <input type="text" v-model="$v.userInternal.mobile.$model" class="text-field w-input"
                   maxlength="256" name="Registration" data-name="Registration" placeholder="000 000" id="Registration">
            <div class="input-error" v-if="!$v.userInternal.mobile.required">Field is Required</div>
            <div class="input-error" v-if="!$v.userInternal.mobile.minLength || !$v.userInternal.mobile.maxLength">Must be 10 digits</div>
          </div>
          <div class="spacing"></div>
        </div>
<!--        <div class="row">-->
<!--          <div class=" col s4">-->
<!--            <label class="device-label">Activated</label>-->
<!--            <b-form-checkbox size="lg" name="check-button" switch-->
<!--                             v-model="userInternal.activated"> Activated-->
<!--            </b-form-checkbox>-->
<!--          </div>-->
<!--        </div>-->
        <div class="row">
          <div class="col s12">
            LastLogin: {{userInternal.last_login}}
          </div>
        </div>
      </b-tab>
      <b-tab title="Devices" v-if="!isNewUser">
        <div class="toolbar">
          <i class="icon-button" :class="$config.icons.general.assign" @click="clickAddDevice" v-b-tooltip.hover title="Assign Device"></i>
          <i class="icon-button-danger" :class="$config.icons.general.unassign" @click="clickUnassignSelected" v-b-tooltip.hover title="Unassign Selected Devices"></i>
        </div>
        <admin-device-list hide-filter :devices="userInternal.devices" @change="changeSelection" @save="getUserData" :show-fields="deviceFields"></admin-device-list>
      </b-tab>
      <b-tab title="Subscriptions">
        <div>NOTE: It's usually best to change a user's Subscription in the Stripe Dashboard!</div>
        <div class=" col s4">
          <label for="stripe_id" class="device-label">Stripe ID</label>
          <input type="text" v-model="userInternal.stripe_id" class="text-field w-input"
                 maxlength="256" placeholder="Stripe ID" id="stripe_id" disabled>
        </div>
        <admin-subscription-list :subscriptions="userInternal.subscriptions" @change="getUserData"></admin-subscription-list>
      </b-tab>
      <b-tab title="Roles">
        <div class="toolbar">
          <i class="icon-button" :class="$config.icons.general.refresh" @click="resetRoles()" v-b-tooltip.hover title="Reset Roles"></i>
        </div>
        <admin-roles-list :roles="userInternal.admin_roles" :show-fields="roleFields" ref="userRoles"></admin-roles-list>
      </b-tab>
      <b-tab title="Shares" v-if="!isNewUser">
        <p>NOTE: This screen shows the Shares the user OWNS (i.e. shares they have granted to other users).
          Changes made here will be performed on behalf of the user, appearing to others as though the user performed
          them. </p>
        <share-device :user_email="userInternal.email" :device_list="userInternal.devices"></share-device>
      </b-tab>
      <b-tab title="Notifications" v-if="!isNewUser">
        <div class="toolbar">
          <b-button class="button" @click="$bvModal.show('modal-user-notifications')">Notifications</b-button>
          <b-button class="button" @click="clickClearNotifications()">Clear All</b-button>
        </div>
        <EditUserNotifications :user="userInternal"></EditUserNotifications>

      </b-tab>
      <b-tab title="Entitlements" v-if="!isNewUser">
        <div class="toolbar">
          <i class="icon-button" :class="$config.icons.general.refresh" @click="getEntitlements(true)" v-b-tooltip.hover title="Refresh List"></i>
          <i class="icon-button" :class="$config.icons.general.add" @click="$bvModal.show('modal-create-entitlement')" v-b-tooltip.hover title="Create Entitlement"></i>
        </div>
        <div class="notification-container">
          <b-table striped hover :items="entitlements" :fields="entitlementFields" select-mode="single" selected-variant="success"
                   th-class="bv-table-header" :current-page="currentPage" :per-page="perPage" @row-clicked="editEntitlement"
                   class="bv-table">

            <!-- Entitlement Order Slot            -->
            <template v-slot:cell(order_id)="row">
              <div v-if="row.item.order_id">
                Order #{{row.item.order_id}}
                <i class="fa fa-external-link row-icon-button" @click="clickEntitlementOrder(row.item)"
                   v-b-tooltip.hover title="Go to Order"></i>
              </div>
              <div v-if="!row.item.order_id">
                Manual
              </div>
            </template>
            <!-- Entitlement Status Slot            -->
            <template v-slot:cell(status)="row">
              <b-select v-model="row.item.status" :options="entitlementStatusOptions" @change="changeEntitlementStatus($event, row.item)"></b-select>
            </template>
            <!-- Entitlement Coupon Code Slot            -->
            <template v-slot:cell(coupon_code)="row">
              <b-select v-model="row.item.coupon_code" :options="couponOptions" @change="changeEntitlementCoupon($event, row.item)"></b-select>
            </template>
          </b-table>
        </div>
      </b-tab>
      <b-tab title="Auditlog" v-if="!isNewUser">
        <h2>Audit Log</h2>
        <audit-log related_type="user" :related_id="user.user_id"></audit-log>
      </b-tab>
    </b-tabs>
    <div class="footer mt-2">
      <b-button class="button btn-warning" @click="getLoginToken">Login As</b-button>
      <b-button class="button btn-warning" @click="$bvModal.show('modal-user-set-password')">Change Password</b-button>
      <b-button class="button" @click="clickClose()">Close</b-button>
      <b-button class="button" @click="saveChanges()">Save Changes</b-button>
    </div>

    <!--    User Assign Device Modal Template-->
    <b-modal id="modal-assign-device" centered class="modal-content" size="xl" hide-footer title="Assign Device to User">
      <admin-assign-devices :user="userInternal" modal="modal-assign-device" v-on:save="onAssignDevice"></admin-assign-devices>
    </b-modal>

    <!--    User Assign Device Modal Template-->
    <b-modal id="modal-create-entitlement" centered class="modal-content" size="m" hide-footer title="Create Entitlement">
      <b-select v-model="newCouponCode" :options="couponOptions"></b-select>
      <div class="footer mt-2">
        <b-button class="button" @click="$bvModal.hide('modal-create-entitlement')">Close</b-button>
        <b-button class="button" @click="createEntitlement()">Create Entitlement</b-button>
      </div>
    </b-modal>

    <!--    User Notifications Modal Template-->
    <b-modal id="modal-user-notifications" centered class="modal-content" size="xl" hide-footer
             :title="userInternal.name+'\'s Notifications'">
      <NotificationList :user-email="userInternal.email"></NotificationList>
    </b-modal>

    <!--    User Set Password Modal Template-->
    <b-modal id="modal-user-set-password" centered class="modal-content" size="xl" hide-footer
             :title="'Set '+ userInternal.name+'\'s Password'">
      <form >
        <label for="new-password" class="device-label">New Password</label>
        <input type="password" v-model="newPassword" class="text-field w-input"
               maxlength="256" name="new-password" data-name="new-password" placeholder="New Password" id="new-password"
              required >
        <label for="confirm-password" class="device-label">New Password</label>
        <input type="password" v-model="confirmNewPassword" class="text-field w-input"
               maxlength="256" name="confirm-password" data-name="new-password" placeholder="Confirm Password"
               id="confirm-password" required
        >
        <b-button class="button" @click="$bvModal.hide('modal-user-set-password')">Cancel</b-button>
        <b-button class="button" @click="changeUserPassword">Change Password</b-button>
      </form>

    </b-modal>

    <!--    Generate Login Token Modal Template-->
    <b-modal id="modal-user-login-token" centered class="modal-content" size="xl" ok-only
             :title="'Login as '+ userInternal.name+'\''">
      <div class="row">
        <div class="col-8">
          <label for="name">Login Token</label>
          <input id="token" class="w-100" v-model="loginTokenURL">
          <button @click="tokenToClipboard">Copy to Clipboard</button>
        </div>
        <div class="col-4">
          <div class="hint-box">
            <h4>Admin Login as User</h4>
            Copy the below Login URL (with token) and paste it into a new INCOGNITO browser window. This will not interfere
            with your current protekt login.

            This should not affect the user's ability to log in or invalidate their existing login token.
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import * as AlertHelper from '../helpers/AlertHelper'
import AdminRolesList from './AdminRolesList'
import NotificationList from '@/components/notifications/NotificationList'
import NotificationSubscriptionList from '@/components/notifications/NotificationSubscriptionList'
import EditUserNotifications from '@/components/notifications/EditUserNotifications'
import ShareDevice from '@/components/device/ShareDevice'
import {adminSetPassword} from '../helpers/DataProvider'
import AuditLog from '@/components/shared/AuditLogList.vue'

export default {
  name: 'admin-edit-user',
  components: {
    AuditLog,
    ShareDevice,
    EditUserNotifications,
    NotificationSubscriptionList,
    NotificationList,
    AdminRolesList,
    // NOTE: We're importing these modules asynchronously to avoid circular dependancy issues with Webpack
    AdminDeviceList: () => import ('./AdminDeviceList'),
    AdminAssignDevices: () => import ('./AdminAssignDevices'),
    AdminSubscriptionList: () => import ('./AdminSubscriptionList')
  },
  props: {
    isModal: Boolean,
    modal: String,
    user: Object,
    userId: String
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      displayDevice: null,
      isNewUser: true,
      selectedDevices: null,
      roleFields: ['id', 'name', 'is_member', 'manual', 'actions'],
      userInternal: {
        selected: false,
        userId: '1',
        devices: ['12345678', '123456678'],
        notifications: [],
        email: 'bob@hotmail.com',
        mobile: '0417554554',
        name: 'Bob Smith',
        activated: false,
        admin: false,
        admin_roles: [
          {
            id: 0,
            name: 'Loading',
            manual: false,
            is_member: false
          }
        ],
        subscriptions: [],
        lastLogin: '2020-01-01 09:90'
      },
      deviceFields: ['selected', 'imei', 'device_code', 'name', 'device_phone', 'has_subscription', 'subscription_expiry', 'actions'],
      entitlementFields: [
        { key: 'entitlement_id',
          label: 'Id',
          sortable: true
        },
        { key: 'order_id',
          label: 'Order Number',
          sortable: true
        },
        { key: 'status',
          label: 'Status',
          sortable: true
        },
        { key: 'coupon_code',
          label: 'Coupon Code',
          sortable: true
        },
        { key: 'created_datetime',
          label: 'Created',
          sortable: true,
          formatter: this.formatTimestamp
        },
        { key: 'modified_datetime',
          label: 'Updated',
          sortable: true,
          formatter: this.formatTimestamp
        }
        // { key: 'actions',
        //   label: 'Actions'
        // }
      ],
      entitlements: [{
        entitlement_id: '',
        status: '',
        modified_datetime: '',
        created_datetime: '',
        coupon_code: ''
      }],
      entitlementStatusOptions: [
        {
          value: 'available',
          text: 'Available'
        },
        {
          value: 'claimed',
          text: 'Claimed'
        },
        {
          value: 'error',
          text: 'Error'
        }
      ],
      // Table Pagination
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 0, text: 'All' }
      ],
      couponData: [],
      couponOptions: [],
      newCouponCode: '',
      newPassword: '',
      confirmNewPassword: '',
      loginTokenURL: null
    }
  },
  validations: {
    userInternal: {
      name: {
        required
      },
      email: {
        required,
        email
      },
      mobile: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10)
      }
    }
  },
  async created () {
    if (this.user) {
      this.userInternal = { ...this.user } // Take a shallow copy to avoid editing the parent component's data directly
      // Note: If we don't at least take a shallow copy we can end up with unexpected behavior such as unsaved changes
      // being visible on the parent component, since JS passes by reference.
      this.isNewUser = false
      await this.getNotifcations()
      await this.getEntitlements()
    } else if (this.userId) {
      this.isNewUser = false
      await this.getUserData(this.userId)
      await this.getNotifcations()
      await this.getEntitlements()
    } else {
      this.userInternal = {
        selected: false,
        userId: '-1',
        devices: [],
        notifications: [],
        email: '',
        mobile: '',
        name: '',
        activated: true,
        admin: false,
        admin_roles: [],
        lastLogin: ''
      }
    }
    // Hide Controls so the parent modal can display them
    if (this.isModal) {
      this.showButtons = false
    }
    await this.loadCoupons()
    console.log('User id: ', this.userId)
    this.loading = false
  },
  methods: {
    // Loading and Setup Functions
    getUserData: async function (userId) {
      if (!userId) {
        userId = this.userInternal.id
      }
      this.selectedDevices = null
      let response = await DataProvider.adminGetUser(userId)
      if (response.success) {
        this.userInternal = response.data
      }
    },
    getNotifcations: async function (isRefresh) {
      let res = await DataProvider.adminGetNotifications(this.userInternal.email)
      if (res.success) {
        this.userInternal.notifications = res.data.alerts
        if (isRefresh) {
          this.$bvToast.toast('Notifications Refreshed!', {
            title: 'Update Successful',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
        }
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    loadCoupons: async function () {
      let couponData = await DataProvider.adminGetCoupons()
      if (couponData.success) {
        this.couponData = couponData.data.filter(x => x.valid)
        this.couponOptions = []
        this.couponData.forEach(coupon => {
          let suffix
          if (coupon.amount_off) {
            suffix = `$${coupon.amount_off / 100}`
          } else {
            suffix = `${coupon.percent_off}%`
          }
          this.couponOptions.push({
            value: coupon.id,
            text: `${coupon.id} - ` + suffix
          })
        })
      } else {
        ErrorHelper.displayDataErrorToast(couponData)
      }
    },
    getEntitlements: async function () {
      let res = await DataProvider.adminGetUserEntitlements(this.userInternal.id)
      if (res.success) {
        this.entitlements = res.data
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    clickClearNotifications: async function () {
      let confirm = await this.$bvModal.msgBoxConfirm('Are you SURE you want to CLEAR this user\'s notification list?', {
        title: 'Confirm Clear Notifications',
        okVariant: 'warning',
        centered: true
      })
      if (confirm) {
        let res = await DataProvider.adminClearAllUserNotifications(this.userInternal.email)
        if (res.success) {
          this.getNotifcations()
        } else {
          ErrorHelper.displayDataErrorToast(res)
        }
      }
    },
    changeEntitlementStatus: async function (val, entitlement) {
      let res = await DataProvider.adminUpdateEntitlement(entitlement.entitlement_id, val)
      if (res.success) {
        AlertHelper.successToast('Entitlement Status Updated', 'Update Successful')
        // Update the entitlement's properties with server data
        for (let prop in res.data) {
          entitlement[prop] = res.data[prop]
        }
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    changeEntitlementCoupon: async function (val, entitlement) {
      let res = await DataProvider.adminUpdateEntitlement(entitlement.entitlement_id, undefined, val)
      if (res.success) {
        AlertHelper.successToast('Entitlement Coupon Code Updated', 'Update Successful')
        for (let prop in res.data) {
          entitlement[prop] = res.data[prop]
        }
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    editEntitlement: function (item) {
      console.log('Edit', item)
    },
    removeEntitlement: async function (item) {
      console.log('Remove', item)
    },
    createEntitlement: async function () {
      console.log('Create entitlement', this.userInternal)
      let res = await DataProvider.adminCreateEntitlement(this.userInternal.id, 'available', this.newCouponCode)
      if (res.success) {
        AlertHelper.successToast('Entitlement Created', 'Entitlement Created')
        this.$bvModal.hide('modal-create-entitlement')
        this.getEntitlements()
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    clickEntitlementOrder: async function (item) {
      console.log('goto order:', item)
      this.$router.push({ path: '/admin/shop-order/' + item.order_id })
    },
    saveChanges: async function () {
      let res
      let newRoles
      if (this.$v.$anyError) {
        ErrorHelper.displayGeneralErrorToast('Some Fields Contain Invalid Data. Please fix them.', 'Invalid Fields')
        return
      }
      if (this.isNewUser) {
        res = await DataProvider.adminCreateUser(
          this.userInternal.name,
          this.userInternal.email,
          this.userInternal.mobile,
          {})
      } else {
        res = await DataProvider.adminUpdateUser(
          this.userInternal.id,
          this.userInternal.name,
          this.userInternal.email,
          this.userInternal.mobile,
          this.userInternal.admin)
        if (this.$refs.userRoles.dirty) {
          let roleIds = this.userInternal.admin_roles.filter(x => x.is_member).map(x => x.name)
          let rolesUpdate = await DataProvider.adminUpdateUserRoles(this.userInternal.id, roleIds)
          if (rolesUpdate.success) {
            AlertHelper.successToast('Roles Updated Successfully', 'Update Successful')
            newRoles = rolesUpdate.data.admin_roles
          } else {
            ErrorHelper.displayDataErrorToast(rolesUpdate)
          }
        }
      }

      if (res.success) {
        this.userInternal = res.data
        if (newRoles) {
          this.userInternal.admin_roles = newRoles
        }
        this.$v.$reset()
        this.$bvToast.toast('The changes you made have been saved!',
          {
            title: 'Changes Saved',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    clickClose: async function () {
      if (this.$v.$anyDirty) {
        let res = await this.$bvModal.msgBoxConfirm('You have unsaved changes, are you sure you want to close this window?', {
          title: 'Confirm Discard Changes',
          okVariant: 'warning',
          centered: true
        })
        if (!res) {
          return
        }
      }
      this.$emit('save')
      // Check if we've been passed a modal to close.
      if (this.modal) {
        this.$bvModal.hide(this.modal)
      } else {
        this.$router.go(-1)
      }
    },
    clickAddDevice: function () {
      this.$bvModal.show('modal-assign-device')
    },
    onAssignDevice: async function (devices) { // Event called when device(s) are assigned from the Assign device modal
      this.$bvModal.hide('modal-assign-device')
      await this.getUserData(this.userInternal.id)
      this.$emit('save')
    },
    changeSelection: function (newVal) {
      this.selectedDevices = newVal
    },
    clickUnassignSelected: async function () {
      console.log('trying to delete: ', this.selectedDevices)
      if (!this.selectedDevices) {
        ErrorHelper.displayGeneralWarningToast('You have not selected any devices.', 'No Device Selected')
        return
      }
      let res = await this.$bvModal.msgBoxConfirm(`Are you sure you want to Unassign ${this.selectedDevices.length} device(s)?`, {
        title: 'Confirm Unassignment',
        okVariant: 'warning',
        centered: true
      })
      if (res) {
        for (let device of this.selectedDevices) {
          let result = await DataProvider.adminUnAssignDevice(device.device_imei)
          if (result.success) {
            AlertHelper.successToast('Device has been unassigned successfully!', 'Device Unassigned.')
          } else {
            ErrorHelper.displayDataErrorToast(result)
          }
        }
        await this.getUserData(this.userInternal.id)
      }
    },
    resetRoles: async function () {
      let confirm = await this.$bvModal.msgBoxConfirm(
        'This will remove all MANUALLY assigned roles and set the user\'s roles back to only those granted by ' +
        'their subscriptions. Are you sure?')
      if (!confirm) {
        return
      }
      let resp = await DataProvider.adminResetUserRoles(this.userInternal.id)
      if (resp.success) {
        this.userInternal.admin_roles = resp.data.roles
        AlertHelper.successToast('User Roles have been Reset!', 'Update Successful')
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    async changeUserPassword () {
      if (this.newPassword !== this.confirmNewPassword) {
        ErrorHelper.displayGeneralErrorToast('Password does not match Confirm Password Field!',
          'Password Not Matched!')
        return
      }
      let resp = await DataProvider.adminSetPassword(this.userInternal.id, this.newPassword)
      if (resp.success) {
        AlertHelper.successToast('Password Changed!', 'Password Changed.')
        this.$bvModal.hide('modal-user-set-password')
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    async getLoginToken() {
      let response = await DataProvider.adminGenerateToken(this.userInternal.email)
      if (response.success) {
        let route = this.$router.resolve({name: 'login', query: {token: response.data.token}})
        this.loginTokenURL = new URL(route.href, window.location.origin).href
        this.$bvModal.show('modal-user-login-token')
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
    },
    tokenToClipboard() {
      navigator.clipboard.writeText(this.loginTokenURL)
      AlertHelper.successToast('Login URL Copied to Clipboard')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .admin-edit-user {
    color: $text-color-invert;
    overflow: auto;
  }

  .row {
    margin: 0px;
  }

  .device-label{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .device-code{
    color: $text-color-invert;
    margin-left: 5%;
  }

  .toolbar {
    display: flex;
    flex-direction: row;
  }

  .icon-button, .icon-button-danger{
    font-size: 1.9rem;
  }

  .footer{
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
  }

  .icon_select_color {
    border: solid $theme-color-primary-3;
  }

  .notification-container {
    display: flex;
    flex-direction: column;
    max-height: 50vh;
    overflow-y: auto;
  }

  .notification-item{
    background: $theme-color-background-1;
  }

</style>
